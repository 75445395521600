import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from '../models';
import { MenuService } from '../services/menu.service';

@Pipe({
  name: 'fromMenu',
  standalone: true,
})
export class FromMenuPipe implements PipeTransform {
  constructor(private menuService: MenuService) {}

  transform(value: string) {
    if (!value) {
      return [];
    }

    if (!this.menuService.menu || !this.menuService.menu[value.toUpperCase()]) {
      console.error('MENU NOT FOUND FOR: ' + value);
      return [];
    }

    var items = this._removeUnpublished([...this.menuService.menu[value.toUpperCase()].menu]);

    return items;
  }

  private _removeUnpublished(items: MenuItem[]) {
    items = [...items.filter(i => i.publish)];
    items.forEach((item, i) => {
      if (item.menuItems?.length > 0) {
        item = {
          ...item,
          menuItems: [...this._removeUnpublished(item.menuItems)],
        };
      }

      items[i] = item;
    });

    return [...items];
  }
}
